/**
    do not modify this file. modify ./mturbo-plugin.ts instead
*/
import { reactive } from "vue"
import {  $mturbo } from "@/mturbo"
/**
    Injected data from apply_filters('vuejs-app/data) is injected in here.
*/
$mturbo.data = reactive((window as any).mturboData);
/**
    This is looking for a meta tag in the head, and using its content to set
    webpacks public path. This allows chunk calls to work correctly.
*/
let tag:any = document.querySelector('meta[name="fweb-vue"]') ?? { content: ""}
__webpack_public_path__ = tag.content

$mturbo.init()

