
let init = (app: any, $mturbo: $mturbo) => { 
        
    let crud = (model: string) => {
        let object = {
            model,
            resource: {},
            action: "",
            fields: {}
        }
        let fn = async (action: string, fields: Object ) => {
            object.action = action
            object.fields = fields
            return await $mturbo.connection(object)
        }
        return {
            init(resource){
                object.resource = resource;
                return {
                    create: (fields) => fn('create', fields),
                    read: (fields) => fn('read', fields),
                    update: (fields) => fn('update', fields),
                    delete: (fields) => fn('delete', fields),
                }
            }
        }
    }
    $mturbo.crud = crud;

}

export let plugin:$mturboPlugin = {
    init
}