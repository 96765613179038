import { defineAsyncComponent } from "vue"
import { fauxRouter } from "@/mturbo/utils/faux-router"
let component = defineAsyncComponent( () => import("./index.vue") );
let provider = defineAsyncComponent( () => import("./provider.vue") );
 
export let plugin:$mturboPlugin = {
    createApp(app: any, $mturbo: $mturbo){
        app.component('faux-router-provider', provider )
        app.component('faux-router', component )
    },
}
 