import { $mturbo } from "@/mturbo"
import { createApp } from "vue"

export let create = function( tag: string, installer?: $mturboPluginInstaller ) {
    /**
    * Create registers a custom element with the tag you provide. 
    * This should be a kebab cased and valid HTML tag, such as foo-bar which becomes <foo-bar>.
    * It should also correspond to a component with the same name registered via init hooks. 
    *
    * When this custom element is loaded, the corresponding registered component is created on the element. 
    * It folds on the element tag so it can automatically pass the used props to the component. 
    * This also allows slots to work normally, even scoped slots, which is something shadowroot cannot do. 
    * 
    * For recursive components, such as components with slots, you can use any component registered via createApp hooks.
    * 
    * Note: Only the top level custom element will act as a custom element, with all children 
    * falling back to their component behavior, unless they also have a custom-element attribute on them. 
    */
    let installerFn = installer ?? function(){}
    
    class elementBase extends HTMLElement {
        connectedCallback(){
            
            let $el = this

            if( $el.getAttribute( 'app-root' ) == undefined ) return;
            /**
                Remove attribute to prevent infinite looping
            */
            $el.removeAttribute('app-root')

            createApp( {} )
                .use( $mturbo.plugins.load('createApp') )
                .use( installerFn, $mturbo )
                .mount( $el )

        }
    }
    
    customElements.define(tag, elementBase )    

}
    



    

