/**
    any extensions to app sohuld be done in here.
 */
import vClickOutside from "click-outside-vue3"


 
let createApp = (app: any, $mturbo: $mturbo) => { 
    app.use(vClickOutside)
    //app.config.warnHandler = (msg, instance, trace) => {}
}

let init = (app: any, $mturbo: $mturbo) => {  }

export let plugin:$mturboPlugin = {
    init,
    createApp
}