import { defineAsyncComponent } from "vue"
let listing = defineAsyncComponent( () => import("./index.vue") );

export let plugin:$mturboPlugin = {
    
    createApp(app: any, $mturbo: $mturbo){
        app.component('product-listing-mobile-controls', listing )
    },

}
 