import { customElement } from "@/mturbo/custom-element"
import { plugins } from "@/mturbo/plugins"
import { store } from "@/mturbo/store"
import { init } from "@/mturbo/init"
import { debug } from "@/mturbo/debug"

import { connection } from "@/mturbo/connection"
import { $array as array, $object as object } from "@/mturbo/utils/dot-prop"
import $events from 'tiny-emitter/instance'

let $mturbo: $mturbo = {
    init,
    customElement,
    plugins,
    store,
    debug,
    connection,
    utils: {
        array, 
        object,
    },
    event: $events
}

window.$mturbo = $mturbo

export {
    $mturbo, 
}

