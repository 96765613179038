import { defineAsyncComponent } from "vue"
let listing = defineAsyncComponent( () => import("./listing.vue") );

export let plugin:$mturboPlugin = {
    
    createApp(app: any, $mturbo: $mturbo){
        app.component('product-listing', listing )
    },

}
 