import { $mturbo } from "@/mturbo" 
let create = () => {
    var $connection:any = null 
    return async (...args: any) => {
        let {
            nonce,
            url
        } = $mturbo.data.crud
        /**
            This bit here will force this to be chunked,
            allowing it to be loaded on demand.
        */
        if(!$connection) {
            let { createConnection } = await import('./connection/create-connection')
            let { connect } = createConnection( {
                url,
                headers: {
                    'X-WP-Nonce': nonce,
                },
            } )
            $connection = connect
        }
        return await $connection(...args)
    }
}
export let connection = create()