import { defineAsyncComponent } from "vue"
import { $mturbo } from "@/mturbo/"
import { watch, nextTick, inject } from "vue"

let component = defineAsyncComponent( () => import("./index.vue") );
export let plugin:$mturboPlugin = {
    createApp(app: any, $mturbo: $mturbo){
        app.component('vehicle-make-search', component )
    },
}
 